import React from "react";

function AnnoNav({ image, anno }) {
  let annoNext;
  let annoPre;

  if (anno < 2024) {
    annoNext = anno + 1;
  }
  if (anno > 2013) {
    annoPre = anno - 1;
  }

  if (anno == 2019) {
    annoNext = anno + 3;
  }

  if (anno == 2015) {
    annoPre = anno - 2;
  }

  if (anno == 2022) {
    annoPre = anno - 2;
  }

  if (anno == 2013) {
    annoNext = anno + 2;
    annoPre = 2024;
  }

  if (anno == 2024) {
    annoPre = anno - 1;
    annoNext = 2013;
  }
  return (
    <div className="">
      <div className="mt-24 flex flex-row items-center w-2/3">
        <a href={annoPre}>
          {/*   <svg
            className="mx-10"
            width="101"
            height="16"
            viewBox="0 0 101 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.292892 7.29289C-0.0976334 7.68342 -0.0976334 8.31658 0.292892 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41422 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292892 7.29289ZM101 7L1 7V9L101 9V7Z"
              fill="#FDF7E4"
            />
          </svg> */}
          <p className="anno-pre mx-10 numero text-[13px] sm:text-[17px] md:text-[30px]">
            {annoPre}
          </p>
        </a>
        <p className="numero text-[25px] sm:text-[28px] md:text-[60px] ">
          {anno}
        </p>
        <a href={annoNext}>
          {/*   <svg
            className="mx-10"
            width="101"
            height="16"
            viewBox="0 0 101 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.292892 7.29289C-0.0976334 7.68342 -0.0976334 8.31658 0.292892 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41422 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292892 7.29289ZM101 7L1 7V9L101 9V7Z"
              fill="#FDF7E4"
            />
          </svg> */}
          <p className="anno-pre mx-10 numero text-[13px] sm:text-[17px] md:text-[30px]">
            {annoNext}
          </p>
        </a>
      </div>
    </div>
  );
}

export default AnnoNav;
