import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import { useNavigate, useLocation } from "react-router-dom";

const Navbar = () => {
  const [connected, setConnected] = useState("Connect Metamask");
  const [admin, setAdmin] = useState("Connect your wallet");
  const [balance, setBalance] = useState("You have no funds");
  const [provider, setProvider] = useState();
  const [address, setAddress] = useState();
  useEffect(() => {
    connectToMetamask();
  }, [provider, address]);

  const connectToMetamask = async () => {
    try {
      if (typeof window.ethereum !== "undefined") {
        const provider = new ethers.providers.Web3Provider(window?.ethereum);
        if (typeof provider !== "undefined") {
        }
        await provider?.send("eth_requestAccounts", []);
        const signer = provider?.getSigner();
        const address = await signer?.getAddress();
        const balance = ethers.utils.formatEther(await signer.getBalance());

        setProvider(provider);
        setConnected("🟢 Connected");
        setAdmin(address);
        setAddress(address);
        setBalance(balance);
      } else {
        alert("Please install Metamask!");
      }
    } catch (err) {
      console.log(err);
      alert("Failed to connect to Metamask!");
    }
  };
  /*   useEffect ( () => { 

    if (typeof window.ethereum !== 'undefined') {
      setConnected("🟢 Connesso"); 
      setAdmin(address);
      setBalance(balance);
      setAddress(address);
      
  
    }
    
  }
  , [connected, address]); */

  const truncatedAdmin =
    admin.length >= 20 ? `...${admin.substring(admin.length - 8)} ` : admin; // Get last 5 characters of admin
  const truncatedBalance =
    balance !== "You have no funds"
      ? Math.floor(balance * 100) / 100 + " ETH"
      : balance; // Get last 5 characters of balance

  const navigate = useNavigate();

  const location = useLocation();
  const isHome = location.pathname === "/";

  function toHome() {
    const value = "/";
    navigate(value);
  }

  return (
    <div id="navcontainer" className="flex ">
      {!isHome && (
        <div
          id="home_button"
          onClick={() => toHome()}
          className="flex items-center fixed bottom-1 md:top-[0px] max-h-8 left-[0] md:absolute z-[10000] px-2 py-2 contract-info Druk-text border-2 rounded-full border-[#FDF7E4] list-inline mt-6 ml-4  justify-end"
        >
          <button>
            <svg
              width="65"
              height="16"
              viewBox="0 0 65 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.292892 7.29289C-0.0976334 7.68342 -0.0976334 8.31658 0.292892 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41422 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292892 7.29289ZM65 7L1 7V9L65 9V7Z"
                fill="#FDF7E4"
              />
            </svg>
          </button>
        </div>
      )}
      <div
        id="account"
        className="flex items-center px-[1.2rem] py-[0.8rem] absolute left-[-50px] md:absolute right-[0px] top-[0px] z-[900]"
      >
        <div className="ml-[0.8rem] cursor-pointer Druk-text font-semibold text-[#FDF7E4] capitalize "></div>
        <div className="w-max-[720px] mx-[0.8rem] flex flex-1 items-center rounded-[0.8rem] bg-[#363840] hover:bg-[#4c505c] capitalize "></div>
        <ul className="contract-info Druk-text border-2 rounded-full border-[#FDF7E4] list-inline mt-3 flex items-center justify-end p-2 capitalize md:h-[32px]">
          <li className="cursor-pointer px-2 font-bold text-[10px] md:text-sm text-[#c8cacd] hover:text-[#FDF7E4]  Druk-text">
            {connected}
          </li>
          <li className="cursor-pointer px-2 font-bold  text-[10px] md:text-sm text-[#c8cacd] hover:text-[#FDF7E4] Druk-text">
            Balance : {truncatedBalance}
          </li>

          <div id="profile">
            <div className="cursor-pointer px-2 text-[10px] md:text-sm font-black text-[#8a939b] hover:text-white">
              <div className="dropdown-content">
                <a className="dropdown-item" onClick={connectToMetamask}>
                  Account: {truncatedAdmin}
                </a>
              </div>
            </div>
          </div>
        </ul>
      </div>{" "}
    </div>
  );
};

export default Navbar;
