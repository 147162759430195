import React from "react";

import Hero from "./components/Hero";
import Testo from "./components/Testo";
import Collezione from "./components/Collezione";
import Benefit from "./components/Benefit";
import Faq from "./components/Faq";

function Home() {
  return (
    <>
      <Hero />
      <Testo />
      <Collezione />
      <Benefit />
      <Faq />
    </>
  );
}

export default Home;
