import React from "react";

import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { useLoader } from "@react-three/fiber";
import { useRef } from "react";
import { useFrame } from "@react-three/fiber";

function Model_Footer() {
  const ref = useRef();
  const gltf = useLoader(GLTFLoader, "./monkey.glb");

  useFrame((state) => {
    const t = state.clock.getElapsedTime();
    ref.current.rotation.set(
      0.1 + Math.cos(t / 80.5) / 20, //y ()si
      Math.sin(t / 40) / 4, // x rotation ()no
      0.3 - (1 + Math.sin(t / 40)) / 8
    );
    ref.current.position.y = (1 + Math.sin(t / 1)) / 2;
  });
  return (
    <group ref={ref}>
      <hemisphereLight intensity={250} />
      <spotLight
        position={[-10, 15, -10]}
        angle={1.13}
        penumbra={5}
        intensity={150}
      />
      <primitive
        object={gltf.scene}
        scale={20}
        position={[0, 15, 1]}
        rotation-x={0.25}
        rotation-y={-0.25}
      />{" "}
    </group>
  );
}
export default Model_Footer;
