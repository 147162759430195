import React from "react";
import Marquee from "react-fast-marquee";
import image2013 from "../assets/2013.webp";
import { Canvas } from "@react-three/fiber";
import { Suspense } from "react";

import { OrbitControls } from "@react-three/drei";
import Model_Footer from "./Model_Footer";

const Footer = () => {
  return (
    <div
      id="footer-container"
      className="h-[400px] bg-[#1A2700] text-[#FDF7E4] bg-footer_back mt-2.5   mt-10 md:mt-18 lg:mt-24"
    >
      <Marquee direction="right" speed="200" autoFill>
        <div
          data-scroll
          data-scroll-direction="horizontal"
          data-scroll-speed="-1"
          className="Liberator font-bold text-[220px]  text-[#FDF7E4] uppercase  "
        >
          {/*   <a
            href="https://www.ideology.it"
            target="_blank"
            className="cursor-pointer"
            style={{ marginLeft: "2in" }}
          ></a> */}
          <a
            href="https://www.ideology.it"
            target="_blank"
            className="cursor-pointer"
            style={{ marginLeft: "1in" }}
          >
            - NFT BIRROCCO
          </a>
        </div>
      </Marquee>
      {/*   <img className="rotate-[20deg] h-[250px] relative  overflow-hidden rounded sticky bottom-[80%] left-[50%] hover:z-[1000]" src={image2013} /> */}
      {/* <Canvas id="2"
              camera={{
                fov: 35,
                near: 20,
                far: 180,
                position: [0, 30, 80],
              }}
             
            >
              <OrbitControls
                enableZoom={false}
                autoRotate={true}
                autoRotateSpeed={0.2}
                maxPolarAngle={Math.PI / 10}
                minPolarAngle={Math.PI / 2}
              />
              {/* <Model_Footer /> 
            </Canvas> */}

      <div className="flex flex-end justify-center ml-4">
        <div class="text-effect Haptik ">
          <h1 class="" data-text={`By Ideology with &#128154`} contenteditable>
            <p>© 2013-2032 Birrocco. All rights reserved. </p>
            <a href="https://www.ideology.it" target="_blank" rel="noreferrer">
              <p>Made with 🔗 by Ideology Creative Studio </p>
            </a>
          </h1>
        </div>
      </div>

      {/* <Model_Footer/> */}
    </div>
  );
};

export default Footer;
