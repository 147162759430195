import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

import Pagina2013 from "./Pagina2013";
import Pagina2015 from "./Pagina2015";
import Pagina2016 from "./Pagina2016";
import Pagina2017 from "./Pagina2017";
import Pagina2018 from "./Pagina2018";
import Pagina2019 from "./Pagina2019";
import Pagina2022 from "./Pagina2022";
import Pagina2023 from "./Pagina2023";
import Pagina2024 from "./Pagina2024";
import Privacy from "./Privacy";

import Navbar from "./components/Navbar";

import Footer from "./components/Footer";

import Home from "./Home";

import Cursor from "./components/Cursor";

export default function App() {
  const ScrollToTop = () => {
    // Extracts pathname property(key) from an object
    const { pathname } = useLocation();

    // Automatically scrolls to top whenever pathname changes
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  };

  return (
    <Router>
      <Cursor />
      <Navbar />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/2013" element={<Pagina2013 />} />
        <Route path="/2015" element={<Pagina2015 />} />
        <Route path="/2016" element={<Pagina2016 />} />
        <Route path="/2017" element={<Pagina2017 />} />
        <Route path="/2018" element={<Pagina2018 />} />
        <Route path="/2019" element={<Pagina2019 />} />
        <Route path="/2022" element={<Pagina2022 />} />
        <Route path="/2023" element={<Pagina2023 />} />
        <Route path="/2024" element={<Pagina2024 />} />
        <Route path="/Privacy" element={<Privacy />} />
        Privacy
      </Routes>

      <Footer />
      <ScrollToTop />
    </Router>
  );
}
