import BenefitItem from "./BenefitItem.js";

import React, { useRef, useEffect } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const Benefit = () => {
  const containerRef = useRef(null);
  const containerTestoRef = useRef(null);
  useEffect(() => {
    const container = containerRef.current;
    const containerTesto = containerTestoRef.current;

    gsap.fromTo(
      container,
      {
        opacity: 0,
        y: "-10%",
      },
      {
        opacity: 1,
        duration: 1,
        y: "0%",
        scrollTrigger: {
          trigger: container,
          start: "top bottom",
          end: "bottom top",
          toggleActions: "play none none reverse",
        },
      }
    );
    gsap.fromTo(
      containerTesto,
      {
        opacity: 0,
        y: "-10%",
      },
      {
        opacity: 1,
        duration: 2,
        y: "0%",
        scrollTrigger: {
          trigger: container,
          start: "top center",
          end: "center top",
          toggleActions: "play none none reverse",
        },
      }
    );
  }, []);

  return (
    <div className="flex-nowrap sm:text-center mx-12 mt-5 md:mt-18 lg:mt-24">
      <div className="flex justify-around">
        <h1
          id="sezione-collezione"
          className=" text-[35px] md:text-[50px]  lg:text-[100px] Druk-Text font-bold  sm:tracking-wider text-[#FDF7E4]"
          ref={containerRef}
        >
          BENEFITS
        </h1>
      </div>{" "}
      <div className="">
        <div className="flex justify-center mt-5 ">
          <p
            className="font-medium Druk Text Wide Trial  text-[#FDF7E4]   mt-[0.8rem] mb-1"
            ref={containerTestoRef}
          ></p>
        </div>
      </div>
      <div
        id="card-container"
        className="grid grid-cols-2 lg:grid-cols-5 gap-x-5 md:gap-x-[3%] md:mx-32 lg:mx-44
    "
      >
        <BenefitItem
          nome_vantaggio={"Exclusive Access to the Virtual Community"}
          className=""
          numero={"1"}
          description="Mascherone NFT holders will gain VIP access to the project's virtual community, where they can interact, share experiences, and participate in exclusive events."
        />

        <div id="pari" className="mt-20  ">
          {" "}
          <BenefitItem
            nome_vantaggio={"Artistic Co-Creation"}
            numero={"2"}
            description="Buyers will have the unique opportunity to actively participate in the project's co-creation, influencing future iterations and helping shape new developments through exclusive survey sessions."
          />{" "}
        </div>

        <BenefitItem
          nome_vantaggio={"Early Access to Exclusive NFT Collections"}
          className=""
          numero={"3"}
          description="Mascherone NFT holders will have the privilege of early access to new digital collections, ensuring the chance to purchase and collect exclusive pieces before the official release."
        />

        <div id="pari" className=" mt-20 ">
          <BenefitItem
            nome_vantaggio={"Special Offers and Private Sales"}
            numero={"4"}
            description="Holders will benefit from priority access and an exclusive experience during the Birrocco - Craft Beer Festival: a tasting of 8 craft beers, a unique sensory journey to enrich their participation in the event."
          />
        </div>
        <BenefitItem
          nome_vantaggio={"Exclusive Long-Term Discount"}
          className=""
          numero={"5"}
          description="In addition to immediate benefits, Mascherone NFT holders will receive a 10% discount, applicable to further pieces for 12 months after purchase, allowing them to expand their collection of Mascherone NFTs. A great opportunity to strengthen their presence in the digital world."
        />
      </div>
    </div>
  );
};

export default Benefit;
