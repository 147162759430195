import React, { useRef, useEffect } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const Testo = () => {
  const containerRef = useRef(null);
  const rightRef = useRef(null);
  const leftRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    const right = rightRef.current;
    const left = leftRef.current;

    gsap.fromTo(
      container,
      {
        opacity: 0,
        y: "-10%",
      },
      {
        opacity: 1,
        duration: 1,
        y: "0%",
        scrollTrigger: {
          trigger: container,
          start: "top bottom",
          end: "bottom top",
          toggleActions: "play none none reverse",
        },
      }
    );

    gsap.to(right, {
      duration: 1,
      ease: "power2.in",
      scrollTrigger: {
        trigger: container,
        start: "top center",
        end: "center center",
        toggleActions: "play none none reverse",
      },
    });

    gsap.to(left, {
      duration: 1,
      ease: "power2.in",
      scrollTrigger: {
        trigger: container,
        start: "top center",
        end: "center center",
        toggleActions: "play none none reverse",
      },
    });
  }, []);

  return (
    <div
      className="container-flex flex-column md:flex-row mb-10 mx-12 md:mx-32  lg:mx-44  md:mt-42  lg:mb-44 "
      ref={containerRef}
    >
      <div className="">
        <div className="flex justify-center ">
          <p className="font-bold Druk-Text w-full text-[#FDF7E4] container-[400px] text-[20px] sm:text-5xl mt-[0.8rem] mb-1">
            The Mascherone is a symbol of Birrocco. Initially an artistic
            element rooted in Baroque tradition, it transforms into a dynamic
            element when projected into the digital world, where past and future
            blend with style, creativity, and innovation.
          </p>
        </div>
        <div className="flex  flex-wrap">
          <p
            className="w-full md:w-1/2 pr-4 pt-8 Druk-Text text-[#FDF7E4] text-[15px] sm:text-[15px] font-normal "
            ref={rightRef}
          >
            The Mascherone, infused with cultural and historical significance,
            takes on a new life through a "metaphysical" dimension that gives it
            an intriguing duality between the tangible and the intangible. It
            breaks free from its physical origins to find itself in a digital
            reality, where its forms repeat and spread in a complex web of
            elements.This transformation opens the doors to a dimension beyond
            its material representation,
          </p>
          <p
            className="w-full md:w-1/2 md:pl-4 pt-8 Druk-Text text-[#FDF7E4] text-[15px] sm:text-[15px] font-normal"
            ref={leftRef}
          >
            allowing the Mascherone to explore new metaphorical and symbolic
            horizons. In this "metaphysical" scenario, the Birrocco Mascherone
            becomes a multifaceted presence, whose digital manifestations echo
            each other, recreating its iconic form in a visual movement, where
            its identity blends with digital creativity, forming a fascinating
            connection between the past and the future.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Testo;
