import React from "react";

function Cards({ handleClickCardNFT, image, IdNFT }) {
  const style = {
    title: `relative text-white text-[46px] font-semibold nft-card-title`,
    description: `text-[#8a939b] container-[400px] text-md mt-[0.8rem] mb-[2.5rem]`,
    ctaContainer: `flex`,
    accentedButton: ` relative font-semibold px-12 py-2 bg-[#2181e2] rounded-lg mr-5 text-white hover:bg-[#42a0ff] cursor-pointer`,
    button: ` relative font-semibold  items-end text-right rounded-lg text-[#e4e8ea] hover:bg-[#4c505c] cursor-pointer`,
    cardContainer: `rounded-[3rem] flex flex-col rounded-[3rem]`,
    cardContainerConditional: `${
      IdNFT === 2013 ||
      IdNFT === 2016 ||
      IdNFT === 2023 ||
      IdNFT === 2019 ||
      IdNFT === 2023
        ? "self-start rotate-[355deg] ml-[20px]"
        : "self-end rotate-[10deg] mr-[40px]"
    }`,
    infoContainer: `h-20  pt-2 rounded-b-lg flex flex-col text-white`,
    author: `flex flex-col justify-center ml-4 nft-card-title`,
    nome_nft: `nft-card-title`,
  };

  return (
    <div id="wrapper" className="flex justify-center">
      <div
        id="single-card-container"
        className={`${style.cardContainer} `}
        onClick={(e) => handleClickCardNFT(e)}
      >
        <img
          className={`rounded-t-lg max-w-[170px] sm:max-w-[220px] lg:max-w-[240px] + ${
            IdNFT === 2013 ||
            IdNFT === 2016 ||
            IdNFT === 2023 ||
            IdNFT === 2019 ||
            IdNFT === 2023
              ? "rotate-[355deg]"
              : "rotate-[10deg]"
          }  
           `}
          src={image}
          alt={`"Birrocco 2013" ${IdNFT}`}
          data-test-id="2013"
        />
        <div className={`${style.cardContainer}`}>
          <div className={`${style.infoContainer}`}>
            <div
              className={`${style.nome_nft} + z-[1000] + ${style.cardContainerConditional}`}
            >
              BIRROCCO {IdNFT}
            </div>

            <button
              id="discover-button"
              className={`${style.button} + ${style.cardContainerConditional}`}
              onClick={(e) => handleClickCardNFT(e)}
              value={IdNFT}
            >
              DISCOVER NOW
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cards;
