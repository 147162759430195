function HeroSingle({ image }) {
  const backgroundImage = image;

  const style = {
    wrapper: `flex justify-center`,
    container: ``,
    contentHeroWrapper: `flex justify-center `,
  };

  return (
    <div className={style.contentHeroWrapper}>
      <div className=" opacity-70  absolute top-[0%] lg:top-[-50%]  left-0  h-screen  ">
        <img
          src={backgroundImage}
          alt={backgroundImage}
          className="opacity-70  w-screen"
        />
      </div>
    </div>
  );
}

export default HeroSingle;
