import React, { useRef, useEffect } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import Faqlib from "react-faq-component";

gsap.registerPlugin(ScrollTrigger);

const data = {
  title: "",
  rows: [
    {
      title: "How can I buy Birrocco NFTs?",
      content: `To buy a Birrocco Mascherone NFT, you must have a cryptocurrency wallet (Metamask, Coinbase Wallet, Trust Wallet, etc.) with a balance equal to the price of the NFT you want to buy.`,
    },
    {
      title: "What are the requirements to buy a Birrocco Mascherone NFT?",
      content: ` There are four requirements to buy a Birrocco Mascherone NFT:        
• You must have a cryptocurrency wallet;
• You need 0.15 ETH in your cryptocurrency wallet for the purchase;
• You need enough ETH in your cryptocurrency wallet for gas fees.`,
    },
    {
      title: "How many Birrocco Mascherone NFTs are available for purchase?",
      content: `For this first drop, a total of 9 Mascheroni will be available for purchase`,
    },
    {
      title: "How can I view my Birrocco Mascherone NFT?",
      content: `Visit the "My NFTs" section after purchasing a Mascherone on birrocconft.com or import your NFT into your crypto wallet.`,
    },
  ],
};

const styles = {
  bgColor: "#1a1a1a",
  titleTextColor: "#FDF7E4",
  rowTitleColor: "#FDF7E4",
  rowContentColor: "#FDF7E4",
  arrowColor: "#FDF7E4",
  rowTitleTextSize: "large",

  rowContentTextSize: "medium",
  rowTitlePaddingBottom: "50px",
  rowTitlePaddingTop: "50px",
  rowContentPaddingTop: "30px",
  rowContentPaddingBottom: "30px",
};

const config = {
  animate: true,
  arrowIcon: "+",
  tabFocus: true,
  // tabFocus: true
};

const Faq = () => {
  const containerRef = useRef(null);
  const containerTestoRef = useRef(null);
  useEffect(() => {
    const container = containerRef.current;
    const containerTesto = containerTestoRef.current;

    gsap.fromTo(
      container,
      {
        opacity: 0,
        y: "-10%",
      },
      {
        opacity: 1,
        duration: 1,
        y: "0%",
        scrollTrigger: {
          trigger: container,
          start: "top bottom",
          end: "bottom center",
          toggleActions: "play none none reverse",
        },
      }
    );
    gsap.fromTo(
      containerTesto,
      {
        opacity: 0,
        y: "-10%",
      },
      {
        opacity: 1,
        duration: 2,
        y: "0%",
        scrollTrigger: {
          trigger: container,
          start: "top center",
          end: "center top",
          toggleActions: "play none none reverse",
        },
      }
    );
  }, []);

  return (
    <div className="flex-nowrap justify-around px-12 md:px-32 lg:px-44 mt-20">
      {" "}
      <div className="flex justify-around">
        <h1
          id="sezione-collezione"
          className="text-[35px] md:text-[60px]  lg:text-[100px] Druk-Text font-bold  sm:tracking-wider text-[#FDF7E4]"
          ref={containerRef}
        >
          FAQ
        </h1>
      </div>{" "}
      <div className=" justify-center  mt-5">
        <div>
          <Faqlib data={data} styles={styles} config={config} />
        </div>
      </div>
    </div>
  );
};

export default Faq;
